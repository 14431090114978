import * as React from 'react';
import ReactDOM from 'react-dom';


import choirConfig from './choirPlayerConfig';
import Player from 'player-application';

import packageJson from '../package.json';

import './index.css';

window.React = React;
ReactDOM.render(
  <React.StrictMode>
    <Player parentVersion={packageJson.version} clientConfig={choirConfig}/>
  </React.StrictMode>,
  document.getElementById('root')
);