/*
pgw 26-jul-2024 Added settings back while testing reload functionality
*/


const config =  {
  labels: {
    appTitle: "Mubbles Player",
    library: "Songs",
    setlist: "My Setlist",
    settings: "Settings"
  },
  images: {
    menuLogo: "/images/choir-player/mubbles.jpg",
    menuBackground: "/images/choir-player/brave_bg1.jpg",
    loginSplash: "/images/choir-player/login-splash.jpeg",
    loginLogo: "/images/choir-player/choirplayer-logo-pink.svg",
    registrationPromo: "/images/choir-player/login-splash.jpeg",
    defaultSetlistImage: "/images/3fire/defaultPlaylist.jpeg",

	menuLogoLink: "https://themubbles.com",
    albumArt: "https://via.placeholder.com/512"
  },
  features: {
    audio: {
      record: true,
      downloadMix: true,
      shareMix: true,
    },

    help: {
      markdownPath: '/help/index.md'
    },

    setlist: {
      enabled: false,
      renameSession: false,
      downloadSetlist: false,
      showHeader: true,
      urlShortenerUrl: "https://www.choirplayer.com/wp-json/v1/shorten/",
      defaultSetlistImageUrl:"https://s3.eu-west-1.amazonaws.com/uploads.choirplayer.com/9758650"
    },
    setlists: {
      enabled: false,
    },
    session: {
      showMixRecord: false,
      showMixDownload: false,
      showMixShare: false,
      showMixReset: true,
      showMixControls: false,
      mixerTemplate: 'choir',
      loadingAnimationFile: 'notes',
      showCueControls: false,
      showLyrics: true,
      initialStemLevel: 1,
      introChannelNames: [],
      click1ChannelNames: [],
      click2ChannelNames: [],
      cueChannelNames: [],
      cacheOnMount: false
    },
    library: {
      alwaysAddSongToMyPlaylist: false,
	  endpoint:"https://sessionplayer.s3.amazonaws.com/mubbles_songlist.json",
      limitedLibrary: [
        "com.3firemusic.mubbles.000001",
        "com.3firemusic.mubbles.000003",
        "com.3firemusic.mubbles.000004",
        "com.3firemusic.mubbles.000005",
        "com.3firemusic.mubbles.000006",
        "com.3firemusic.mubbles.000007",
        "com.3firemusic.mubbles.000008",
        "com.3firemusic.mubbles.000009",
        "com.3firemusic.mubbles.000010",
        "com.3firemusic.mubbles.000011",
        "com.3firemusic.mubbles.000012",
        "com.3firemusic.mubbles.000013",
        "com.3firemusic.mubbles.000014",
        "com.3firemusic.mubbles.000015",
        "com.3firemusic.mubbles.000016",
        "com.3firemusic.mubbles.000017",
        "com.3firemusic.mubbles.000018",
        "com.3firemusic.mubbles.000019",
        "com.3firemusic.mubbles.000020",
        "com.3firemusic.mubbles.000021",
        "com.3firemusic.mubbles.000022",
        "com.3firemusic.mubbles.000023",
        "com.3firemusic.mubbles.000024",
        "com.3firemusic.mubbles.000025",
        "com.3firemusic.mubbles.000026",
        "com.3firemusic.mubbles.000027",
        "com.3firemusic.mubbles.000028",
        "com.3firemusic.mubbles.000029",
        "com.3firemusic.mubbles.000030",
        "com.3firemusic.mubbles.000031",
        "com.3firemusic.mubbles.000032",
        "com.3firemusic.mubbles.000033",
        "com.3firemusic.mubbles.000034",
        "com.3firemusic.mubbles.000035"
      ],
      subscribeConfig: {
        libraryTitle: "Click on the song below to play it",
        libraryText: "",
        libraryPromoText: "Click on the button below to subscribe to the full Choir Player",
        libraryPromoButtonText: "Subscribe",
        text: "You are not currently subscribed to ChoirPlayer, so this song is unavailable. Try one of the free songs, or ...",
        playlistCreationDeniedText: "You are not currently subscribed to ChoirPlayer, so you cannot create a playlist.",
        buttonLabel: "Subscribe to ChoirPlayer now!",
        url: "https://www.choirplayer.com/product/monthly-subscription/"
      },
      songsToHide: [],
      songsWorkingOnIos: []
    },
    analytics: {
      token: "G-QGDSN1N3G8"
    },
    auth: {
      mode: 'domainCookie',
      domainCookie: {
        cookieId: 'session_id',
        loginUrl: '',
        dashboardUrl: "https://www.choirplayer.com/my-account/",
        userInfoUrl: "https://choirplayer.com/wp-json/v1/user/",
        logoutUrl: "",
        loginLabel: "",
        logoutLabel: "",
        loggedOutState: {
          user: {
            ID: "domainCookie-guest",
            displayName: "Guest User",
            subscribed: false,
            guest: true
          },
          status: 'fulfilled',
          errorMessage: null
        },
        loggedInState: {
          user: null,
          status: 'fulfilled',
          errorMessage: null
        }
      }
    },
    notifications: {
      timeout: 4000
    }
  },
  theme: {
    darkMode: false,
    colours: {
      primary: '#c4327a',
      primaryAlt: '#515151'
    }
  },
  logic: {
    trimStemTitles: true,
    applyExtraLogicToStemTitles: true,
    deniedBrowsers: ["Samsung Internet"],
    restrictedDevices: ["iPhone", "iPad"]
  },
  aws: {
    credentials: {
      accessKeyId: "AKIA4HS77K2RWAD37CNZ",
      secretAccessKey: "FqVAvWTuJaXcJAQIQlx7m\+\+Y7ZC\/6XAU9HACAw\/k",
      region: "eu-west-1"
    },
    s3: {
      bucketName:"uploads.choirplayer.com",
    }
  }
};

export default config;
